import React from 'react';
import { IconButton, Text, Tooltip, useToast } from '@chakra-ui/react';
import { MdFileDownload } from 'react-icons/md';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewSheet } from '../../api';
import {
  TOAST_SUCCESS_OPTION,
  TOAST_ERROR_OPTION,
} from '../../constant/systemMessage';
import { createTemplateData } from './createTemplateData';

type Props = {
  id: string;
};

export const DownloadButton: React.VFC<Props> = ({ id }) => {
  const toast = useToast();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;

  const handleDownload = async () => {
    try {
      const result = await getInterviewSheet(tenantId, id);
      if (result.ok) {
        const jsonData = createTemplateData(await result.json());
        const jsonString = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${jsonData[0].name}.json`;
        link.click();
        URL.revokeObjectURL(url);
        toast({
          title: '問診票のテンプレートを出力しました',
          ...TOAST_SUCCESS_OPTION,
        });
      } else {
        throw new Error(result.status.toString());
      }
    } catch (error) {
      toast({
        title: `エラー {${error}}`,
        description: (
          <>
            <Text>エラーが発生しました。</Text>
            <Text>恐れ入りますが、再度操作をやり直してください。</Text>
          </>
        ),
        ...TOAST_ERROR_OPTION,
      });
    }
  };

  return (
    <Tooltip label="問診票のテンプレートとして出力">
      <IconButton
        aria-label="export"
        icon={<MdFileDownload />}
        colorScheme="webInterview"
        fontSize="24px"
        onClick={handleDownload}
      />
    </Tooltip>
  );
};
