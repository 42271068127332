import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { MdPostAdd } from 'react-icons/md';
import { newId } from '../../domains/newId';
import templates from '../../interview-sheet-template.json';
import { InterviewSheetTemplate } from '../../types/interviewSheetTemplate';
import { SetInterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { SetQuestionContext } from '../../contexts/QuestionContext';
import { SetQuestionItemContext } from '../../contexts/QuestionItemContext';
import { SetConditionContext } from '../../contexts/ConditionContext';
import { SetPrerequisiteContext } from '../../contexts/PrerequisiteContext';
import {
  ADD_INTERVIEW_SHEET,
  GET_INTERVIEWSHEET,
  REMOVE_INTERVIEWSHEETS,
} from '../../actions/interviewSheetAction';
import {
  GET_INTERVIEWSHEET as GET_QUESTIONS,
  REMOVE_QUESTIONS,
} from '../../actions/questionAction';
import {
  GET_INTERVIEWSHEET as GET_QUESTION_ITEMS,
  REMOVE_QUESTION_ITEMS,
} from '../../actions/questionItemAction';
import {
  GET_INTERVIEWSHEET as GET_CONDITIONS,
  REMOVE_CONDITIONS,
} from '../../actions/conditionAction';
import {
  GET_INTERVIEWSHEET as GET_PREREQUISITES,
  REMOVE_PREREQUISITE_ITEMS,
} from '../../actions/prerequisiteAction';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import {
  AgeOperator,
  AnswerType,
  ItemType,
  Sex,
} from '../../types/webInterview';
import { Tag } from '../../types/tag';
import { TOAST_ERROR_OPTION } from '../../constant/systemMessage';

type Props = {
  isDisabled: boolean;
  tags: Tag[];
};

export const NewSheetButton: React.VFC<Props> = ({ isDisabled, tags }) => {
  const { dispatch: sheetDispatch } = useContext(SetInterviewSheetContext);
  const { dispatch: questionDispatch } = useContext(SetQuestionContext);
  const { dispatch: questionItemDispatch } = useContext(SetQuestionItemContext);
  const { dispatch: conditionDispatch } = useContext(SetConditionContext);
  const { dispatch: prerequisiteDispatch } = useContext(SetPrerequisiteContext);
  const history = useHistory();
  const newSheetId = newId('InterviewSheet');

  const [jsonData, setJsonData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const handleMenuItemClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const text = e.target?.result as string;
        const data = JSON.parse(text);
        setJsonData(data);
        setError(null);
      } catch (err) {
        setError('Invalid JSON file');
        setJsonData(null);
      }
    };

    reader.readAsText(file);
  };

  const handleItemSelect = (
    templateData: InterviewSheetTemplate | undefined,
  ) => {
    sheetDispatch({
      type: REMOVE_INTERVIEWSHEETS,
    });

    questionDispatch({
      type: REMOVE_QUESTIONS,
    });

    questionItemDispatch({
      type: REMOVE_QUESTION_ITEMS,
    });

    conditionDispatch({ type: REMOVE_CONDITIONS });

    prerequisiteDispatch({ type: REMOVE_PREREQUISITE_ITEMS });

    sheetDispatch({ type: ADD_INTERVIEW_SHEET, interviewSheetId: newSheetId });

    if (templateData) {
      sheetDispatch({
        type: GET_INTERVIEWSHEET,
        data: {
          ...defaultInterviewSheet,
          interviewSheetId: newSheetId,
          name: templateData.name,
          description: templateData.description,
          conditionPid: templateData.conditionPid as ItemType,
          conditionZipCode: templateData.conditionZipCode as ItemType,
          conditionStreetAddress:
            templateData.conditionStreetAddress as ItemType,
          conditionMailAddress: templateData.conditionMailAddress as ItemType,
          isNew: true,
        },
      });

      const newQuestions = templateData.questions.map((q, idx) => ({
        ...q,
        answerType: q.answerType as AnswerType,
        interviewSheetId: newSheetId,
        questionId: newId('None'),
        sortNumber: idx + 1,
      }));

      const systemTags = tags.filter(({ isSystemOnly }) => isSystemOnly);
      const userTags = tags.filter(({ isSystemOnly }) => !isSystemOnly);
      const newQuestionItems = newQuestions
        .map((q) =>
          q.questionItems.map((qi, idx) => ({
            ...qi,
            tags: qi.tags.map(
              (tagName) =>
                systemTags.find(({ name }) => name === tagName)?.tagId ??
                userTags.find(({ name }) => name === tagName)?.tagId ??
                '', // システムタグがない場合はユーザータグを返す
            ),
            questionId: q.questionId,
            questionItemId: newId('None'),
            sortNumber: idx + 1,
          })),
        )
        .flat();

      const newConditions = newQuestions.map((q) => ({
        ...q.condition,
        ageOperator: q.condition.ageOperator as AgeOperator,
        sex: q.condition.sex as Sex,
        questionId: q.questionId,
        conditionId: newId('None'),
        prerequisiteQuestionId:
          newQuestions.find(
            (nq) =>
              nq.questionNumber === q.condition.prerequisiteQuestionNumber,
          )?.questionId ?? '',
      }));

      const newPrerequisites = newConditions
        .map((c) =>
          c.prerequisiteItems.map((pi) => ({
            prerequisiteId: newId('None'),
            conditionId: c.conditionId,
            questionId: c.prerequisiteQuestionId,
            questionItemId:
              newQuestionItems.find(
                (qi) =>
                  qi.questionId === c.prerequisiteQuestionId &&
                  qi.questionItemNumber === pi.questionItemNumber,
              )?.questionItemId ?? '',
          })),
        )
        .flat();

      questionDispatch({
        type: GET_QUESTIONS,
        data: newQuestions,
      });

      questionItemDispatch({
        type: GET_QUESTION_ITEMS,
        data: newQuestionItems,
      });

      conditionDispatch({
        type: GET_CONDITIONS,
        data: newConditions,
      });

      prerequisiteDispatch({
        type: GET_PREREQUISITES,
        data: newPrerequisites,
      });
    }
    history.push(`/interviewsheets/${newSheetId}`);
  };

  useEffect(() => {
    const process = () => {
      try {
        if (jsonData) {
          handleItemSelect(jsonData[0]);
        }
      } catch (error) {
        console.log(error.message);
        setError(`ファイルのフォーマットに誤りがあります。`);
      }
    };
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'エラー',
        description: (
          <Text>
            {error}
            <br />
            画面を開き直してからやり直してください。
          </Text>
        ),
        ...TOAST_ERROR_OPTION,
      });
      setError(null);
    }
  }, [error, setError, toast]);

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          aria-label="new sheet"
          leftIcon={<MdPostAdd size="24px" />}
          colorScheme="webInterview"
          disabled={isDisabled}
        >
          新規作成
        </MenuButton>
        <MenuList onChange={(value) => console.log(value)}>
          <MenuItem onClick={() => handleItemSelect(undefined)} key={0}>
            <MenuItemText>空のテンプレートから作成する</MenuItemText>
          </MenuItem>
          {templates.map((template, idx) => (
            <MenuItem onClick={() => handleItemSelect(template)} key={idx + 1}>
              <MenuItemText>{template.name}から作成する</MenuItemText>
            </MenuItem>
          ))}
          <MenuItem onClick={handleMenuItemClick}>
            <MenuItemText>ファイルから作成する</MenuItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      {/* 非表示のfile input */}
      <input
        type="file"
        accept=".json"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </>
  );
};

const MenuItemText: React.FC = ({ children }) => (
  <Text color="gray.500" fontWeight="bold">
    {children}
  </Text>
);
