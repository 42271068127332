import { GetInterviewSheet } from '../../types/interviewSheetApiTypes';
import {
  AgeOperator,
  AnswerType,
  ItemType,
  Sex,
} from '../../types/webInterview';

type TemplateType = {
  templateId: string;
  name: string;
  description: string;
  conditionPid: ItemType;
  conditionZipCode: ItemType;
  conditionStreetAddress: ItemType;
  conditionMailAddress: ItemType;
  questions: Question[];
};

type Question = {
  questionNumber: number;
  answerType: AnswerType;
  text: string;
  required: boolean;
  hasCondition: boolean;
  questionItems: QuestionItem[];
  condition: Condition;
};

type QuestionItem = {
  questionItemNumber: number;
  choiceText: string;
  hasChoiceNotes: boolean;
  choiceNotesPrefix: string;
  choiceNotesSuffix: string;
  choicePlaceholder: string;
  descriptionPrefix: string;
  descriptionSuffix: string;
  descriptionPlaceholder: string;
  tags: string[];
};

type Condition = {
  ageRequired: boolean;
  age: number;
  ageOperator: AgeOperator;
  sexRequired: boolean;
  sex: Sex;
  questionRequired: boolean;
  prerequisiteQuestionNumber: number;
  prerequisiteItems: { questionItemNumber: number }[];
};

export const createTemplateData = ({
  id,
  name,
  description,
  condition_pid,
  condition_mail_address,
  condition_zip_code,
  condition_street_address,
  questions,
}: GetInterviewSheet): TemplateType[] => {
  const questionNumbers = questions.map((q) => ({
    questionId: q.id,
    questionNumber: q.sort_number,
  }));

  const questionItemNumbers = questions.flatMap((q) =>
    q.question_items.map((item, idx) => ({
      questionId: q.id,
      questionItemId: item.id,
      questionItemNumber: idx + 1,
    })),
  );

  console.log({ questionNumbers, questionItemNumbers });
  return [
    {
      templateId: id,
      name,
      description,
      conditionPid: condition_pid,
      conditionMailAddress: condition_mail_address,
      conditionZipCode: condition_zip_code,
      conditionStreetAddress: condition_street_address,
      questions: questions.map((q) => ({
        questionNumber: q.sort_number,
        answerType: q.type as AnswerType,
        text: q.text,
        required: q.required,
        hasCondition:
          q.condition.age_required ||
          q.condition.sex_required ||
          q.condition.question_required,
        questionItems: q.question_items.map((item, idx) => ({
          questionItemNumber: idx + 1, //item.sort_number,
          choiceText: item.choice_text,
          hasChoiceNotes: item.has_choice_notes,
          choiceNotesPrefix: item.choice_notes_prefix,
          choiceNotesSuffix: item.choice_notes_suffix,
          choicePlaceholder: item.choice_placeholder,
          descriptionPrefix: item.description_notes_prefix,
          descriptionSuffix: item.description_notes_suffix,
          descriptionPlaceholder: item.description_placeholder,
          tags: item.tags.map((t) => t.name),
        })),
        condition: {
          ageRequired: q.condition.age_required,
          age: q.condition.age,
          ageOperator: q.condition.age_operator,
          sexRequired: q.condition.sex_required,
          sex: q.condition.male
            ? 'male'
            : q.condition.female
            ? 'female'
            : 'not-known',
          questionRequired: q.condition.question_required,
          prerequisiteQuestionNumber:
            questionNumbers.find(
              (num) => num.questionId === q.condition.prerequisite_question_id,
            )?.questionNumber ?? 0,
          prerequisiteItems: questionItemNumbers
            .filter(
              (num) =>
                num.questionId === q.condition.prerequisite_question_id &&
                q.condition.prerequisite_items.some(
                  (pi) => pi.question_item_id === num.questionItemId,
                ),
            )
            .map(({ questionItemNumber }) => ({ questionItemNumber })),
        },
      })),
    },
  ];
};
