import React from 'react';
import { Box, Text, IconButton, HStack, Link, Tooltip } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { dateConvert } from '../../utils/dateConvert';
import { ReleaseBadge } from './ReleaseBadge';
import { CopyButton } from './CopyButton';
import { DownloadButton } from './DownloadButton';

type Props = {
  id: string;
  title: string;
  summary: string;
  updatedAt: string;
  releaseDate: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const InterviewSheetCard: React.VFC<Props> = ({
  id,
  title,
  summary,
  updatedAt,
  releaseDate,
  setLoading,
  setReload,
}) => {
  return (
    <Box
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      background="white"
      shadow="lg"
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color="blue.600"
        align="left"
        maxH="16"
        minH="16"
      >
        {title}
      </Text>
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="gray.600"
        align="left"
        mt="3"
        mb="2"
        maxH="16"
        minH="16"
      >
        {summary.length > 20 ? summary.substring(0, 20) + '...' : summary}
      </Text>
      <Box w="full" align="left">
        <ReleaseBadge releaseDate={new Date(releaseDate)} />
      </Box>
      <HStack align="start" spacing="3" my="3">
        <Link as={ReactRouterLink} to={`/InterviewSheets/${id}`}>
          <Tooltip label="問診票を編集">
            <IconButton
              aria-label="edit"
              icon={<MdEdit />}
              colorScheme="webInterview"
              fontSize="24px"
            />
          </Tooltip>
        </Link>
        <CopyButton id={id} setLoading={setLoading} setReload={setReload} />
        <DownloadButton id={id} />
      </HStack>
      <Box align="left" mt="1">
        <Text color="gray.600" fontWeight="medium">
          公開日：{dateConvert(releaseDate, 'minutes')}
        </Text>
        <Text color="gray.600" mt="1" fontWeight="medium">
          更新日：{dateConvert(updatedAt, 'seconds')}
        </Text>
      </Box>
    </Box>
  );
};
