import React from 'react';
import { Text, IconButton, Tooltip, useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { MdFileCopy } from 'react-icons/md';
import { createPostData } from './createPostData';
import { PostInterviewSheet } from '../../types/interviewSheetApiTypes';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewSheet, postInterviewSheet } from '../../api';
import {
  TOAST_SUCCESS_OPTION,
  TOAST_ERROR_OPTION,
} from '../../constant/systemMessage';

type Props = {
  id: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const CopyButton: React.VFC<Props> = ({ id, setLoading, setReload }) => {
  const toast = useToast();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const userId = user?.sub ?? '';
  const userName = user?.name ?? '';
  const { tenantId } = auth0UserData;

  const handleCopy = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'create:interview-sheets',
      });
      // get
      const result = await getInterviewSheet(tenantId, id);
      if (result.ok) {
        const data: PostInterviewSheet = createPostData(await result.json());
        // post
        const response = await postInterviewSheet(
          tenantId,
          userId,
          encodeURI(userName),
          accessToken,
          data,
        );
        if (response.ok) {
          toast({
            title: '問診票のコピーを作成しました',
            ...TOAST_SUCCESS_OPTION,
          });
        } else {
          throw new Error(result.status.toString());
        }
      } else {
        throw new Error(result.status.toString());
      }
    } catch (error) {
      toast({
        title: `エラー {${error}}`,
        description: (
          <>
            <Text>エラーが発生しました。</Text>
            <Text>恐れ入りますが、再度操作をやり直してください。</Text>
          </>
        ),
        ...TOAST_ERROR_OPTION,
      });
      console.error(error);
    } finally {
      setReload.toggle();
    }
  };

  return (
    <Tooltip label="問診票をコピーして追加">
      <IconButton
        aria-label="copy"
        icon={<MdFileCopy />}
        colorScheme="webInterview"
        fontSize="24px"
        onClick={handleCopy}
      />
    </Tooltip>
  );
};
